const courses = [
  "System Design for Interviews and Beyond, LeetCode.",
  "Docker and Kubernetes: The Complete Guide, Udemy.",
  "Just Express (with a bunch of node and http). In detail, Udemy.",
  "Microservices with Node JS and React, Udemy.",
  "The Complete Node.js Developer Course (3rd Edition), Udemy.",
  "Node JS: Advanced Concepts, Udemy.",
  "Typescript: The Complete Developer's Guide, Udemy.",
];

export default courses;
