import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import BasicLayout from "../layouts/BasicLayout";
import Experience from "../components/Experience";
import Education from "../components/Education";
import Skills from "../components/Skills";
import Seo from "../components/seo";
import "./styles/resume.scss";
import Languages from "../components/Languages";
import Courses from "../components/Courses";
import Download from "../images/download-icon.png";
import CV from "../utils/carlos-perez-cv-english.pdf";

export default function Resume() {
  return (
    <BasicLayout>
      <Seo title="Resume" />
      <div className="body-resume">
        <Container>
          <Row>
            <Col xs={12}>
              <div className="title-container d-flex align-items-center">
                <h1>Resume</h1>
                <div className="download-icon-container">
                  <a
                    href={CV}
                    download="Carlos Pérez CV.pdf"
                    role="button"
                    aria-labelledby="download icon"
                    aria-label="download icon"
                  >
                    <Image className="avatar" src={Download} />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Experience />
          <hr />
          <Skills />
          <hr />
          <Courses />
          <hr />
          <Education />
          {/* <hr />
          <Languages /> */}
        </Container>
      </div>
    </BasicLayout>
  );
}
