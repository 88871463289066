import React from 'react'
import { Row, Col } from 'react-bootstrap'
import courses from "../utils/courses"
import "./style.scss"

export default function Courses() {
  return (
    <Row className="resume-info">
      <Col xs={12} md={7}>
        <h2>Courses</h2>
      </Col>
      <Col xs={12} md={5}>
        <Row className="info">
          <Col xs={12}>
            <ul>
              {courses.map((courses, index) => (
                <li className='mb-3' key={index}>{courses}</li>
              ))}
            </ul>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
