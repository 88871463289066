import ZigiRecommendation from "./zigi-cto-recommendation.pdf";

const experience = [
  {
    date: "March, 2023 - May, 2024",
    title: "Software Engineer",
    company: "Zigi App",
    linkedin: "https://www.linkedin.com/company/zigiapp",
    description: [
      "I developed a referral program which increased the number of users by 90% (from 3k to 30k). This referral program has helped reach the new user adoption goal every month since its launch in July 2023.",
      "I designed and implemented a gamification program which increased the retention rate by 20%. This program is 100% dynamic, meaning it allows for the creation of plenty of challenges each month with no extra development time.",
      "I built a lambda function to filter fraudulent users in the referral program, which resulted in a 100% decrease in audit alerts.",
      "I led the Backend team to successfully meet deadlines by creating an ACH transfer module, significantly increasing stakeholder satisfaction.",
    ],
    recommendation:
      "https://www.linkedin.com/in/carlosdp7/overlay/1717535178025/single-media-viewer/?profileId=ACoAADdYLecBEG3gQpCDTJpRsN-RpCWu8aq9mvo",
    stack:
      "Tech-Stack: NestJS, TypeScript, TypeORM, Postgres, Auth0, AWS, Terraform, Terraspace, Jest, Docker, Kubernetes, Mockoon, SQS, SNS, Pintpoint, Mermaid, Serverless, Lambda.",
  },
  {
    date: "November, 2019 - March, 2023",
    title: "Software Engineer",
    company: "Aurora Studio",
    linkedin: "https://www.linkedin.com/company/aurora-studio-tech",
    description: [
      "I designed a system capable of exchanging, buying, and selling collectible cards, implementing the microservices architecture.",
      "I developed a real-time chat system that increased communication between users and administrators by 100%.",
      "I implemented an optimal solution for a credit simulator capable of supporting more than 50 thousand vehicles.",
      "I successfully integrated various third-party APIs (such as Google Maps, Stripe, Shopify, etc.), significantly reducing development time.",
      "As a team, we successfully finalized a delivery system, meeting deadlines and achieving 100% customer satisfaction.",
      "I met with clients to understand their needs and improve the end-user experience.",
    ],
    stack:
      "Tech-Stack: Docker, Kubernetes, Redis, Web Scraping, Microservices, Node.js, TypeScript, React.js, Gatsby.js, Next.js, Bootstrap, REST APIs, Git, GitHub, Jest, Playwright, MongoDB, Express.js, Socket.io.",
  },
];

export default experience;
